import React, { FC, ReactNode } from 'react';
import Stack from '@mui/material/Stack';

import Backdrop from '@mui/material/Backdrop';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import ProgressSpinner from '@/components/ProgressSpinner';
import { Paragraph } from '@/theme/text-variants';
import { colorTokens } from '@/theme/color-tokens';
import { colors } from '../../theme/colors';

const theme = createTheme({});

interface PageLoadingProps {
  text?: string;
  open: boolean;
  longWaitTime?: boolean;
  isSplitScreen?: boolean;
}

const PageLoading: FC<PageLoadingProps> = ({ longWaitTime, open = false, isSplitScreen, text }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledBackdrop open={open} isSplitScreen={isSplitScreen}>
        <Stack height={isSplitScreen ? "50vh" : "100vh"} justifyContent="center" alignItems="center" gap="24px">
          <ProgressSpinner size="64px" color={colorTokens.iconPrimary} />
          <Stack gap="8px" alignItems="center" justifyContent="center" padding="24px">
            <Paragraph size="lg" color={colorTokens.iconPrimary} fontWeight="bold" textAlign="center">
              {text ?? "Loading..."}
            </Paragraph>
            {longWaitTime && (
              <Paragraph size="sm" color={colorTokens.iconPrimary} fontWeight="bold" textAlign="center">
                Almost there!
              </Paragraph>
            )}
          </Stack>
        </Stack>
      </StyledBackdrop>
    </ThemeProvider>
  );
};

export default PageLoading;

const StyledBackdrop = styled(Backdrop)<PageLoadingProps>(({isSplitScreen}) => ({
  zIndex: '8000',
  background: colors.white,
  height: isSplitScreen ? "50vh" : "100vh"
}));
